// setup container for the functions
window.reece = window.reece || {};

import "@reece-trs-online/components/dist/postcode-update.js";
import "@reece-trs-online/components/dist/add-to-cart.js";

import "./../_common/main.scss";
import "./../_common/print.scss";
import "./header";
import "./../site-message/site-message";

// other scripts
import "./../scripts/max-lite/index";

// load the search bar web component
let searchbarWebComponent = document.createElement("script");
let headEl = document.getElementsByTagName('head')[0]
searchbarWebComponent.src = "/search/static/search-bar/no-cache/search-bar.js";
headEl.appendChild(searchbarWebComponent);

// load maX analytics script
let analyticsScript = document.createElement("script");
analyticsScript.type = "text/javascript";
analyticsScript.src = "/max/web-analytics/static/js/max-analytics.nocache.js";
headEl.appendChild(analyticsScript);

// load the stencil web component library
const stencilWebComponentRef = {
  aws:    `/web-components/web-components/web-components.js`,
  local:  "/web-component-library/web-components.js" 
};
let webComponentLibrarySrc = stencilWebComponentRef.local;

let adobeAnalyticsModule = document.createElement("script");
adobeAnalyticsModule.src = webComponentLibrarySrc;
adobeAnalyticsModule.type = "module";
headEl.appendChild(adobeAnalyticsModule);
let adobeAnalyticsNomodule = document.createElement("script");
adobeAnalyticsNomodule.src = webComponentLibrarySrc;
adobeAnalyticsNomodule.noModule = true;
headEl.appendChild(adobeAnalyticsNomodule);

const rdmStencilWebComponentRef = {
  module: `/build/rdm-web-components.esm.js`,
  noModule: `/build/rdm-web-components.js`
};

let rdmWebComponentLibrarySrcModule = rdmStencilWebComponentRef.module;
let rdmWebComponentLibrarySrcNoModule = rdmStencilWebComponentRef.noModule;

let rdmModule = document.createElement("script");
rdmModule.src = rdmWebComponentLibrarySrcModule;
rdmModule.type = "module";
headEl.appendChild(rdmModule);
let rdmNoModule = document.createElement("script");
rdmNoModule.src = rdmWebComponentLibrarySrcNoModule;
rdmNoModule.noModule = true;
headEl.appendChild(rdmNoModule);

const rdmStencilWebComponentCss = `/css/site-message.css`;

const link  = document.createElement('link');
link.rel  = 'stylesheet';
link.type = 'text/css';
link.href = rdmStencilWebComponentCss;
headEl.appendChild(link);

const gothamCss = `/components/assets/css/gotham.css`;
const gothamLink  = document.createElement('link');
gothamLink.rel  = 'stylesheet';
gothamLink.type = 'text/css';
gothamLink.href = gothamCss;
headEl.appendChild(gothamLink);

// insert the adobe analytics module (once the <body> tag has loaded)
document.addEventListener("DOMContentLoaded", function() {
  let adobeAnalyticsEl = document.createElement("rg-analytics-xdm");
  let bodyEl = window.document.getElementsByTagName('body')[0];
  bodyEl.insertBefore(adobeAnalyticsEl, bodyEl.firstChild);
}, false);
